<template>
	<div class="o-wrap">
		<el-page-header @back="$router.go(-1)" content="我的积分"/>
		<div class="jifen-tit">
			<div class="jifen-tit_left">
				<!-- <div>当前会员等级：初级会员</div> -->
				<!-- <div>当前积分等级：</div> -->
				<div>当前信誉值：{{credit}}</div>
			</div>
			<div class="jifen-tit_right">
				<div class="jifen-tit_right_t1">
					<!-- <router-link to="/jf_recharge">去充值</router-link>&nbsp;|&nbsp;<router-link to="/jf_shop">去使用</router-link> -->
				</div>
				<a class="jifen-tit_right_t2" :href="$store.state.ROOT_URL+'/vip'">会员权益说明</a>
			</div>
		</div>
		<div class="jifen-content">
			<div class="jifen-content_left">
				<div class="jifen-content_left_item">
					<h4>总积分（定级积分）</h4>
					<p>{{credit}}</p>
				</div>
				<div class="jifen-content_left_item">
					<h4>可用的积分</h4>
					<p>{{integral}}</p>
				</div>
				<div class="jifen-content_left_item">
					<h4>已过期的积分</h4>
					<p>0</p>
				</div>
			</div>
			<div class="jifen-content_right">
				说明：<br />积分可用于消费、兑换等操作，信誉值是体现商家的综合信誉表现与等级，且不可使用，如商家发布的产品、新闻、采购等其他信息违法了平台的相关规定，平台会扣除相应的积分和信誉值。</div>
		</div>
		<el-tabs style="width: 1568px;margin: 10px auto;" v-model="activeName" type="card" @tab-click="handleClick">
			<el-tab-pane label="积分收入" name="first" />
			<el-tab-pane label="积分支出" name="second" />
		</el-tabs>
		<el-table
			:data="tableData.slice((pagination.currentPage-1)*pagination.pageSize,pagination.currentPage*pagination.pageSize)"
			stripe current-row-key="id">
			<el-table-column prop="yt" label="来源/用途" />
			<el-table-column prop="score" label="积分变化">
				<template slot-scope="scope">
					<p>{{scope.row.score|scoreState(index)}}</p>
				</template>
			</el-table-column>
			<el-table-column prop="time" label="日期" />
			<el-table-column prop="address" label="备注">
				<template slot-scope="scope">
					<p>{{scope.row.yt}}</p>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination layout="prev, pager, next" :total="pagination.total" :hide-on-single-page="pagination.hide"
			:page-size="pagination.pageSize" :current-page="pagination.currentPage" @current-change="currentChange">
		</el-pagination>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				activeName: 'first',
				tableData: [],
				index: 1,
				credit: 0,
				integral: 0,
				pagination: {
					hide: true,
					total: 0,
					pageSize: 10,
					currentPage: 1
				}
			}
		},
		created() {
			this.getScoreList();
		},
		methods: {
			getScoreList() {
				this.$axios.post('/getScoreList', {
					type: this.index
				}).then(res => {
					this.integral = res.jifen.integral
					this.credit = res.jifen.credit
					this.tableData = res.scoreList || []
					this.pagination.total = res.scoreList.length
				})
			},
			handleClick(tab, event) {
				this.pagination.currentPage = 1;
				this.index = parseInt(tab.index) + 1;
				this.getScoreList()
			},
			currentChange(size) {
				this.pagination.currentPage = size
			}
		},
		filters: {
			scoreState(value, index) {
				let state = index == 1 ? '+' + value : '-' + value;
				return state
			}
		}
	}
</script>

<style scoped>
	.o-wrap{
		width: 1600px;
		background-color: #fff;
	}
	i {
		font-style: normal;
	}

	.el-card p {
		line-height: 40px;
	}

	.el-card span {
		font-size: 24px;
		color: #09F;
	}

	.wrap .el-tabs {
		margin-top: 20px;
	}

	.el-row--flex {
		align-items: center;
	}

	.jifen-tit {
		width: 1524px;
		border: 1px solid #e6e6e6;
		display: flex;
		height: 60px;
		align-items: center;
		padding: 0 18px;
		font-size: 16px;
		justify-content: space-between;
		margin:  0 auto;
	}

	.jifen-tit_left,
	.jifen-tit_right {
		display: flex;
		align-items: center;
	}

	.jifen-tit_left div {
		margin-right: 78px;
	}

	.jifen-tit_right_t1,
	.jifen-tit_right_t1 a {
		color: #3a82db;
	}

	.jifen-tit_right_t1 {
		margin-right: 64px;
	}

	.jifen-tit_right_t2 {
		font-size: 14px;
		color: #999999;
		position: relative;
		text-indent: 22px;
	}

	.jifen-tit_right_t2::before {
		content: '';
		position: absolute;
		width: 16px;
		height: 16px;
		background: url(../../assets/img/que_mark.png);
		background-size: 16px;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
	}

	.jifen-content {
		width: 1568px;
		margin: 12px auto;
		display: flex;
		justify-content: space-between;
	}

	.jifen-content_left {
		padding: 18px 0;
		background: #f8f8f8;
		border: 1px solid #e6e6e6;
		display: flex;
		flex: 1;
	}

	.jifen-content_left_item {
		flex: 1;
		text-align: center;
		border-left: 1px solid #e6e6e6;
	}

	.jifen-content_left_item:first-of-type {
		border-left: none;
	}

	.jifen-content_left_item h4 {
		font-size: 16px;
		color: #999999;
		line-height: 37px;
	}

	.jifen-content_left_item p {
		font-size: 32px;
		line-height: 47px;
	}

	.jifen-content_right {
		box-sizing: border-box;
		border: 1px solid #e6e6e6;
		width: 608px;
		padding: 0 21px;
		margin-left: 18px;
		font-size: 14px;
		color: #fd4242;
		line-height: 28px;
		display: flex;
		align-items: flex-end;
	}
</style>
